var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "card-title-actions"
  }, [_c('v-spacer'), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light"
    },
    on: {
      "click": function click($event) {
        return _vm.$previous();
      }
    }
  }, [_vm._v("Cancel")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save);
      }
    }
  }, [_vm._v(" Save ")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save, true);
      }
    }
  }, [_vm._v(" Save & close ")])], 1), _c('v-card-text', {
    staticClass: "pt-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "name",
      "label": "Name",
      "required": ""
    },
    model: {
      value: _vm.role.name,
      callback: function callback($$v) {
        _vm.$set(_vm.role, "name", $$v);
      },
      expression: "role.name"
    }
  })], 1), _vm.role.type === _vm.ROLE_TYPE.USER ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Has Admin Privilege?"
    },
    model: {
      value: _vm.role.hasAdminPrivilege,
      callback: function callback($$v) {
        _vm.$set(_vm.role, "hasAdminPrivilege", $$v);
      },
      expression: "role.hasAdminPrivilege"
    }
  })], 1) : _vm._e()], 1)], 1), _vm.role.type ? _c('span', [_vm.role.type === _vm.ROLE_TYPE.ACCOUNT ? _c('ProfileRole', {
    attrs: {
      "role": _vm.role
    }
  }) : _c('UserRole', {
    attrs: {
      "role": _vm.role
    }
  })], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }