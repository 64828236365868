export default {
  methods: {
    mergePermissions(permissions) {
      permissions.forEach(permission => {
        if (permission.children) {
          this.mergePermissions(permission.children);
        } else {
          const _permission = this.role.getPermission(permission.value);
          if (_permission) {
            permission.value.values = _permission.values;
          }
        }
      });
    },
    onChangeGroupPermisison(permission) {
      this.onChangePermisison(permission.value);
      permission.children.forEach(_childPermission => {
        if (this.role.hasPermission(permission.value)) {
          this.role.addPermission(_childPermission.value);
        } else {
          this.role.removePermission(_childPermission.value);
        }
      });
    },
    onChangeChildPermisison(parentPermission, permission) {
      this.onChangePermisison(permission.value);
      const isAllChildSelected = parentPermission.children.every(_childPermission =>
        this.role.hasPermission(_childPermission.value)
      );
      if (isAllChildSelected) {
        this.role.addPermission(parentPermission.value);
      } else {
        this.role.removePermission(parentPermission.value);
      }
    },
    onChangePermisison(permission) {
      if (this.role.hasPermission(permission)) {
        this.role.removePermission(permission);
      } else {
        this.role.addPermission(permission);
      }
    },

    onChangeValue(permission) {
      this.role.removePermission(permission);
      if (permission.values.length) {
        this.role.addPermission(permission);
      }
    },
  },
};
