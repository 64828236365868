<template>
  <v-expansion-panels accordion>
    <v-expansion-panel v-for="(permission, index) in permissions" :key="index">
      <v-expansion-panel-header>{{ permission.text }}</v-expansion-panel-header>

      <v-expansion-panel-content>
        <v-list v-for="(_permission, index) in permission.children" :key="index" dense>
          <template v-if="_permission.children">
            <v-subheader>{{ _permission.text }}</v-subheader>
            <template v-for="(__permission, index) in _permission.children">
              <v-list-item v-if="_permission.value.name == 'account.user'" :key="index">
                <v-list-item-title v-text="__permission.text"></v-list-item-title>
                <vx-input
                  id="_id"
                  v-model="__permission.value.values"
                  type="select"
                  :items="roles"
                  name="Tags"
                  item-text="name"
                  item-value="_id"
                  multiple
                  @change="onChangeValue(__permission.value)"
                />
              </v-list-item>

              <v-list-item v-else :key="index">
                <v-list-item-title v-text="__permission.text"></v-list-item-title>

                <v-checkbox
                  style="width: 200px"
                  :input-value="role.hasPermission(__permission.value)"
                  @click="onChangePermisison(__permission.value)"
                />
              </v-list-item>
            </template>
          </template>
          <template v-else>
            <v-list-item>
              <v-list-item-title v-text="_permission.text"></v-list-item-title>

              <v-checkbox
                style="width: 200px"
                :input-value="role.hasPermission(_permission.value)"
                @click="onChangePermisison(_permission.value)"
              />
            </v-list-item>
          </template>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import _ from 'lodash';
import { PERMISSIONS } from '@tutti/constants';
import RoleMixin from '../role.mixin';
import { RoleService } from '@tutti/services';

export default {
  name: 'ProfileRole',
  mixins: [RoleMixin],
  props: {
    role: { type: Object },
  },
  data() {
    return {
      tab: 0,
      loading: false,
      permissions: _.cloneDeep(PERMISSIONS),
      roles: [],
    };
  },
  async created() {
    await this.getRoles();
    this.mergePermissions(this.permissions);
    this.created = true;
  },
  methods: {
    async getRoles() {
      const response = await RoleService.get();
      if (response) {
        this.roles = response.data.data || [];
      }
    },
  },
};
</script>
