<template>
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title class="card-title-actions">
        <v-spacer />
        <vx-btn width="100" outlined color="light" class="ml-2" @click="$previous()">Cancel</vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" :loading="loading" @click="$validate(save)">
          Save
        </vx-btn>
        <vx-btn outlined color="light" class="ml-2" :loading="loading" @click="$validate(save, true)">
          Save & close
        </vx-btn>
      </v-card-title>

      <v-card-text class="pt-8">
        <v-row>
          <v-col cols="12" sm="4" class="py-0">
            <vx-input v-model="role.name" type="text" name="name" label="Name" required />
          </v-col>
          <!-- <v-col cols="12" sm="2" class="py-0">
            <v-checkbox v-model="role.isActive" label="Is Enabled?" />
          </v-col> -->
          <v-col v-if="role.type === ROLE_TYPE.USER" cols="12" sm="6" class="py-0">
            <v-checkbox v-model="role.hasAdminPrivilege" label="Has Admin Privilege?" />
          </v-col>
        </v-row>
      </v-card-text>
      <span v-if="role.type">
        <ProfileRole v-if="role.type === ROLE_TYPE.ACCOUNT" :role="role" />
        <UserRole v-else :role="role" />
      </span>
    </v-card>
  </v-form>
</template>

<script>
import { RoleService } from '@tutti/services';
import { Role } from '@tutti/models';
import ProfileRole from './profile';
import UserRole from './user';
import { ROLE_TYPE } from '@tutti/constants';

export default {
  name: 'RolePageEdit',
  components: {
    ProfileRole,
    UserRole,
  },
  data() {
    return {
      tab: 0,
      loading: false,
      created: false,
      role: new Role(),
      ROLE_TYPE,
    };
  },

  async created() {
    this.tab = this.$route?.params?.tab || 0;
    await this.getRole();
    this.created = true;
  },

  methods: {
    async getRole() {
      if (this.$route.params.id) {
        const response = await RoleService.getById(this.$route.params.id);

        if (response) {
          this.role = new Role(response.data);
          this.$setTitle(this.role.name);
        }
      } else {
        this.role.type = this.$route?.query?.roleType || null;
      }
    },

    async save(previous) {
      this.loading = true;
      this.role.slug = this.$slugify(this.role.name, '_');
      const response = await RoleService.save(this.role.get());
      if (response) {
        if (previous) {
          this.$previous();
        } else if (response.data._id) {
          this.$replace(this.$toEdit('role', response.data._id));
        }
      }
      this.loading = false;
    },
  },
};
</script>
