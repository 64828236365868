<template>
  <div>
    <v-expansion-panels accordion>
      <v-expansion-panel v-for="(permission, index) in permissions" :key="index">
        <v-expansion-panel-header :hide-actions="!permission.children?.length">
          <template>
            <v-list-item>
              <v-list-item-title class="text-subtitle-2" v-text="permission.text"></v-list-item-title>

              <v-checkbox
                :class="{ 'mr-7': !permission.children?.length }"
                style="width: 200px"
                :input-value="role.hasPermission(permission.value)"
                @click.native.stop="onChangeGroupPermisison(permission)"
              />
            </v-list-item>
          </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content v-if="permission.children?.length">
          <v-list v-for="(_permission, index) in permission.children" :key="index" dense>
            <template v-if="_permission.children">
              <template>
                <v-list-item>
                  <v-list-item-title class="" v-text="_permission.text"></v-list-item-title>

                  <v-checkbox
                    class="mr-7"
                    style="width: 200px"
                    :input-value="role.hasPermission(_permission.value)"
                    @click="onChangeChildPermisison(permission, _permission)"
                  />
                </v-list-item>
              </template>
            </template>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import _ from 'lodash';
import { USER_PERMISSIONS } from '@tutti/constants';
import RoleMixin from '../role.mixin';

export default {
  name: 'UserRole',
  mixins: [RoleMixin],
  props: {
    role: { type: Object },
  },
  data() {
    return {
      tab: 0,
      loading: false,
      permissions: _.cloneDeep(USER_PERMISSIONS),
    };
  },
  created() {
    this.mergePermissions(this.permissions);
    this.created = true;
  },
};
</script>
