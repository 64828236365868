var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-expansion-panels', {
    attrs: {
      "accordion": ""
    }
  }, _vm._l(_vm.permissions, function (permission, index) {
    var _permission$children, _permission$children2, _permission$children3;

    return _c('v-expansion-panel', {
      key: index
    }, [_c('v-expansion-panel-header', {
      attrs: {
        "hide-actions": !((_permission$children = permission.children) !== null && _permission$children !== void 0 && _permission$children.length)
      }
    }, [[_c('v-list-item', [_c('v-list-item-title', {
      staticClass: "text-subtitle-2",
      domProps: {
        "textContent": _vm._s(permission.text)
      }
    }), _c('v-checkbox', {
      class: {
        'mr-7': !((_permission$children2 = permission.children) !== null && _permission$children2 !== void 0 && _permission$children2.length)
      },
      staticStyle: {
        "width": "200px"
      },
      attrs: {
        "input-value": _vm.role.hasPermission(permission.value)
      },
      nativeOn: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.onChangeGroupPermisison(permission);
        }
      }
    })], 1)]], 2), (_permission$children3 = permission.children) !== null && _permission$children3 !== void 0 && _permission$children3.length ? _c('v-expansion-panel-content', _vm._l(permission.children, function (_permission, index) {
      return _c('v-list', {
        key: index,
        attrs: {
          "dense": ""
        }
      }, [_permission.children ? [[_c('v-list-item', [_c('v-list-item-title', {
        domProps: {
          "textContent": _vm._s(_permission.text)
        }
      }), _c('v-checkbox', {
        staticClass: "mr-7",
        staticStyle: {
          "width": "200px"
        },
        attrs: {
          "input-value": _vm.role.hasPermission(_permission.value)
        },
        on: {
          "click": function click($event) {
            return _vm.onChangeChildPermisison(permission, _permission);
          }
        }
      })], 1)]] : _vm._e()], 2);
    }), 1) : _vm._e()], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }