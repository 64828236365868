var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-expansion-panels', {
    attrs: {
      "accordion": ""
    }
  }, _vm._l(_vm.permissions, function (permission, index) {
    return _c('v-expansion-panel', {
      key: index
    }, [_c('v-expansion-panel-header', [_vm._v(_vm._s(permission.text))]), _c('v-expansion-panel-content', _vm._l(permission.children, function (_permission, index) {
      return _c('v-list', {
        key: index,
        attrs: {
          "dense": ""
        }
      }, [_permission.children ? [_c('v-subheader', [_vm._v(_vm._s(_permission.text))]), _vm._l(_permission.children, function (__permission, index) {
        return [_permission.value.name == 'account.user' ? _c('v-list-item', {
          key: index
        }, [_c('v-list-item-title', {
          domProps: {
            "textContent": _vm._s(__permission.text)
          }
        }), _c('vx-input', {
          attrs: {
            "id": "_id",
            "type": "select",
            "items": _vm.roles,
            "name": "Tags",
            "item-text": "name",
            "item-value": "_id",
            "multiple": ""
          },
          on: {
            "change": function change($event) {
              return _vm.onChangeValue(__permission.value);
            }
          },
          model: {
            value: __permission.value.values,
            callback: function callback($$v) {
              _vm.$set(__permission.value, "values", $$v);
            },
            expression: "__permission.value.values"
          }
        })], 1) : _c('v-list-item', {
          key: index
        }, [_c('v-list-item-title', {
          domProps: {
            "textContent": _vm._s(__permission.text)
          }
        }), _c('v-checkbox', {
          staticStyle: {
            "width": "200px"
          },
          attrs: {
            "input-value": _vm.role.hasPermission(__permission.value)
          },
          on: {
            "click": function click($event) {
              return _vm.onChangePermisison(__permission.value);
            }
          }
        })], 1)];
      })] : [_c('v-list-item', [_c('v-list-item-title', {
        domProps: {
          "textContent": _vm._s(_permission.text)
        }
      }), _c('v-checkbox', {
        staticStyle: {
          "width": "200px"
        },
        attrs: {
          "input-value": _vm.role.hasPermission(_permission.value)
        },
        on: {
          "click": function click($event) {
            return _vm.onChangePermisison(_permission.value);
          }
        }
      })], 1)]], 2);
    }), 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }